/* eslint-disable react/jsx-boolean-value */
/* eslint-disable no-irregular-whitespace */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable comma-dangle */
/* eslint-disable react/no-danger */
import React, { FC } from 'react';
import Layout from '@/common/ui/template/Layout';
import { PageProps } from 'gatsby';

import freedialGreen from '@/newcar/ui/common/img/freedial/freedial-green.svg';
import ButtonContact from '@/newcar/ui/common/img/faq/btn-contact.svg';
import ButtonContactLine from '@/newcar/ui/common/img/faq/btn-contact-line.svg';
import Header from '@/newcar/ui/common/organism/Header';
import Breadcrumb from '@/newcar/ui/common/molecule/Breadcrumb';
import Footer from '@/newcar/ui/common/organism/Footer';
import FAQAccordion from '@/newcar/ui/docs/faq/organism/FAQAccordion';
import { Element } from 'react-scroll';
import ScrollToTop from '@/common/ui/organism/ScrollToTop';
import SEO from '@/newcar/ui/docs/faq/organism/SEO';
import AnchorLink from '@/common/ui/atom/AnchorLink';
import useScrollToElementOnLoad from '@/common/ui/hook/useScrollToElementOnLoad';
import FAQSchemaMarkup from '@/newcar/ui/docs/faq/organism/FAQSchemaMarkup';
import NEWCAR_DEFINE from '@/newcar/util/define';
import Card from '@/newcar/ui/detail/molecule/Card';
import tripleCrown from '@/newcar/ui/detail/img/triple_crown.svg';
import costWebp from '@/newcar/ui/detail/img/cost.webp';
import costJpg from '@/newcar/ui/detail/img/cost.jpg';
import supportWebp from '@/newcar/ui/detail/img/support.webp';
import supportJpg from '@/newcar/ui/detail/img/support.jpg';
import satisfyPriceWebp from '@/newcar/ui/detail/img/satisfyPrice.webp';
import satisfyPriceJpg from '@/newcar/ui/detail/img/satisfyPrice.jpg';
import CTA_SP from '@/newcar/ui/detail/molecule/CTA_SP';
import CTA_PC from '@/newcar/ui/detail/molecule/CTA_PC';
import FaqCategory from '@/common/core/model/faqCategory';
import FaqContent from '@/common/core/model/faqContent';
import CarDigest from '@/common/core/model/carDigest';
import NEWCAR_CONST from '@/newcar/util/const';
import miraes from '@/newcar/ui/top/img/car-image/mira-es.jpg';
import * as styles from './index.module.styl';
import FaqData from '../../../../../../json/newcar_faq.json';

type TopPageContext = {
  carDigests: CarDigest[];
}

const Page: FC<PageProps<{}, TopPageContext>> = ({ pageContext }) => {
  const {
    carDigests,
  } = pageContext;

  const targetDigest = carDigests.find((digest) => digest.id__normalized.toString() === NEWCAR_CONST.QUICK.ID.EASY);
  const isNowSale = targetDigest ? targetDigest.publishStatus === NEWCAR_CONST.PUBLISH.STATUS.SALE : false;
  const quickEasyPrice = targetDigest ? (targetDigest.taxedMinPrice11).toLocaleString() : '';

  const faqCategories: FaqCategory[] = FaqData.categories;
  const faqContents: FaqContent[] = FaqData.contents;

  useScrollToElementOnLoad({ duration: 300, smooth: true, offset: -50 });

  function compareFaqByOrderBy(a: FaqContent, b: FaqContent): number {
    if (a.orderBy && b.orderBy) {
      return (a.orderBy > b.orderBy ? 1 : -1);
    } return 0;
  }

  return (
    <Layout>
      <SEO />
      <FAQSchemaMarkup
        items={faqContents
          .filter(({ orderBy }) => orderBy) // todo 後で消す orderbyがnot null
          .sort(
            (a, b) => (a.orderBy ?? 0) - (b.orderBy ?? 0)
          )
          .map(({ question = '', answer = '' }) => ({ question, answer }))}
      />
      <Header />
      <Breadcrumb
        items={[
          { to: NEWCAR_DEFINE.PATH.TOP, label: '新車TOP' },
          { label: 'よくあるご質問' },
        ]}
      />
      <main>
        <div className={`${styles.sectionArea} ${styles.sectionBgMain}`}>
          <section>
            <h2 className={`is-size-3 ${styles.mainHeading}`}>
              よくあるご質問
            </h2>
            <div className={`container ${styles.faqContainerHelper}`}>
              <section key="" className={styles.faqSection}>
                <div className={styles.innerContainer}>
                  <h3 className={`heading ${styles.faqHeading}`}>
                    よく見られているご質問
                  </h3>
                  <div className={styles.faqContainer}>
                    <ul>
                      {faqContents
                        .filter((item) => item.pick_up === true)
                        .sort(compareFaqByOrderBy)
                        .map((item) => (
                          <FAQAccordion
                            className={`js-accordion-trigger ${styles.faqList}`}
                            key={item.question}
                            qa={item}
                            goods="gtm-newcar-faq-popular"
                            isFrequentlyAsked={true}
                          />
                        ))}
                    </ul>
                    {/* <a className={styles.faqCategoryLink} href="#categoryLink">カテゴリ選択に戻る</a> */}
                  </div>
                </div>
              </section>

              <section>
                <div className={styles.innerContainer}>
                  <h3 id="categoryLink" className={`heading ${styles.faqHeading}`}>
                    カテゴリから探す
                  </h3>
                  <div className={`${styles.faqContainer} ${styles.isCategory}`}>
                    <ul className={styles.list}>
                      {/* data-gtm="[種別]-faq-cat[N]" */}
                      {faqCategories.map((c) => (
                        <li key={`faq-category-link-${c.name}`}>
                          <AnchorLink
                            to={c.id ?? ''}
                            options={{ duration: 300, smooth: true, offset: -50 }}
                            className={`is-relative is-flex js-anchor ${styles.listButton}`}
                            dataGtm={`gtm-newcar-faq-category-${c.id}`}
                          >
                            <span
                              className={`${styles.listText}`}
                            >
                              {c.name || ''}
                            </span>
                          </AnchorLink>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </section>

              <section className="is-hidden">
                <div className={styles.innerContainer}>
                  <h3 className={`heading ${styles.faqHeading}`}>
                    キーワードから探す
                  </h3>

                  <div className={styles.faqContainer}>
                    <div className={styles.keywordInputContainer}>
                      <input
                        className={styles.keywordInputField}
                        placeholder="探したいQ&Aを入力"
                        type="text"
                      />
                      <button
                        className={styles.keywordInputButton}
                        type="submit"
                      />
                    </div>
                    <div className={styles.keywordTipContainer}>
                      <span className={styles.keywordTipItem}>黒ナンバー</span>
                      <span className={styles.keywordTipItem}>審査</span>
                      <span className={styles.keywordTipItem}>家族契約</span>
                      <span className={styles.keywordTipItem}>メンテナンスプラン</span>
                      <span className={styles.keywordTipItem}>連帯保証人</span>
                      <span className={styles.keywordTipItem}>年齢に制限</span>
                      <span className={styles.keywordTipItem}>法人名義</span>
                    </div>
                  </div>
                </div>
              </section>

              <section>
                <div className={styles.innerContainer}>
                  <div className={`faq ${styles.contentSpacing}`}>
                    {faqCategories.map((c) => (
                      <section
                        key={`faq-category-${c.name}`}
                        className={styles.faqSection}
                      >
                        <Element
                          name={c.id ?? ''}
                          id={c.id ?? ''}
                        >
                          <h3 className={`heading ${styles.faqHeading}`}>
                            {c.name}
                          </h3>
                        </Element>
                        <div className={styles.faqContainer}>
                          <ul>
                            {faqContents
                              .filter((item) => item.category === c.id)
                              .map((item) => (
                                <FAQAccordion
                                  className={`js-accordion-trigger ${styles.faqList}`}
                                  key={item.question}
                                  qa={item}
                                  goods="gtm-newcar-faq"
                                  isFrequentlyAsked={false}
                                />
                              ))}
                          </ul>
                          <a data-gtm={`gtm-newcar-faq-${c.id}-back`} className={styles.faqCategoryLink} href="#categoryLink">カテゴリ選択に戻る</a>
                        </div>
                      </section>
                    ))}
                  </div>
                </div>
              </section>

              <section>
                <div className={styles.innerContainer}>
                  <h3 className={`heading ${styles.faqHeading}`}>
                    お問合わせ
                  </h3>
                  <p className={styles.faqContactText}>
                    こちらのページで解決できないことは、お気軽に
                    <br />
                    お問合わせください。
                  </p>
                  <div className={styles.faqContactContainer}>
                    <div className={styles.faqContactCard}>
                      <h4>メールでのお問合わせ</h4>
                      <a href="/contact/" data-gtm="gtm-newcar-faq-contact">
                        <img src={ButtonContact} alt="0120-559-442" />
                      </a>
                    </div>
                    <div className={styles.faqContactCard}>
                      <h4>
                        定額カルモくん公式LINEアカウント
                      </h4>
                      <a href="https://liff.line.me/1657071928-Br8GdoPL?sl=952e1e635d" data-gtm="gtm-newcar-faq-line">
                        <img src={ButtonContactLine} alt="0120-559-442" />
                      </a>
                    </div>
                    <div className={styles.faqContactCard} style={{ display: 'none' }}>
                      <h4>お電話でのお問合わせ</h4>
                      <a href="tel:0120-559-442" className={styles.faqContactLinkTel}>
                        <img src={freedialGreen} alt="0120-559-442" />
                      </a>
                      <span className={styles.faqContactTextMin}>受付時間：平日10:00〜19:00（土日・祝日・年末年始休み）</span>
                    </div>
                  </div>
                </div>
              </section>

              <section style={{ display: 'none' }}>
                {/* 三冠 */}
                <div className={`container ${styles.faqCharmContainerHelper}`}>
                  <Card>
                    <h2 className="title is-3 has-text-centered is-margin-bottom-5">専門家が選ぶカーリース</h2>
                    <div className="is-margin-bottom-6 has-text-centered">
                      <img
                        src={tripleCrown}
                        width="295"
                        height="78"
                        alt="三冠獲得"
                        decoding="async"
                      />
                    </div>
                    <div className="columns">
                      <picture className={`${styles.marginAdjust} column has-text-centered is-margin-bottom-3`}>
                        <source srcSet={costWebp} type="image/webp" />
                        <source srcSet={costJpg} />
                        <img src={costJpg} alt="コストパフォーマンスが高いと思うカーリースNO.1" loading="lazy" decoding="async" width="314" height="78" />
                      </picture>
                      <picture className={`${styles.marginAdjust} column has-text-centered is-margin-bottom-3`}>
                        <source srcSet={supportWebp} type="image/webp" />
                        <source srcSet={supportJpg} />
                        <img src={supportJpg} alt="サポートが充実していると思うカーリースNO.1" loading="lazy" decoding="async" width="314" height="78" />
                      </picture>
                      <picture className="column has-text-centered is-margin-bottom-3">
                        <source srcSet={satisfyPriceWebp} type="image/webp" />
                        <source srcSet={satisfyPriceJpg} />
                        <img src={satisfyPriceJpg} alt="納得価格思うカーリースNO.1" loading="lazy" decoding="async" width="314" height="78" />
                      </picture>
                    </div>
                    <p className="has-text-grey is-size-7 is-padding-right-4 is-padding-left-4 is-margin-bottom-4">
                      【出典】調査方法:インターネット調査 調査期間:2021年2月12日~15日 調査概要:カーリース10社を対象にしたイメージ調査　調査対象:男女、全国、18~69 歳、運転免許保有、(1)(2)週 1 回以上運転するファイナンシャルプランナー176s、(3)カーディーラー勤務者 297s　調査実施:株式会社ショッパーズアイ　比較対象企業:「カーリース」Google検索9社(2021年1月29日10時時点)※広告、まとめサイトなどを除く
                    </p>

                    {isNowSale && (
                      <>
                        {/* CTA部分_SP版 */}
                        <div className="is-hidden-desktop">
                          {/* // NOTE: 実装時にはここに動的に車両情報を読み込んでください。仮表示のためにtrueを渡しています */}
                          <CTA_SP
                            thumbnailUrl={miraes}
                            monthlyPrice={quickEasyPrice}
                          />
                        </div>

                        {/* CTA部分_PC版 */}
                        <div className="is-cta is-hidden-mobile is-hidden-tablet-only">
                          {/* // NOTE: 実装時にはここに動的に車両情報を読み込んでください。 */}
                          <CTA_PC
                            thumbnailUrl={miraes}
                            monthlyPrice={quickEasyPrice}
                            uniqueStyle="widthAuto"
                          />
                        </div>
                      </>
                    )}
                  </Card>
                </div>
              </section>
            </div>
          </section>
          {/* </div> */}

        </div>
      </main>

      <Footer />
      <ScrollToTop />
    </Layout>
  );
};

export default Page;
